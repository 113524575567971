import React from 'react';
import { calculatePercentageDiscount } from '../utils/helpers';
import { IntlUtility } from '../../../../helpers';

import {
  daysDiffToString,
  daysRemainingForOffer,
  Offer,
  offerHasExpired,
} from '../../../../data-fetching/useActiveOffers';
import { toPounds } from '../../../account/Payout/PayoutSummaryCards';

interface Props {
    price: number;
    strikethrough_price?: number;
    offer?: null | Offer
}

export const ItemPrice: React.FC<Props> = ({ price, strikethrough_price, offer }) => {
    const discount = calculatePercentageDiscount(price, strikethrough_price || 0);
    const amountSavedInPoundsAndPence = strikethrough_price ? strikethrough_price - price : 0;

    if (offer && !offerHasExpired(offer)) {
      const daysTilOfferExpires = daysRemainingForOffer(offer);

        return (
          <div className='flex items-center justify-between'>
              <p className='body-16-bold'>
                  <span
                    className={'text-brandRed'}>Offer Accepted:
                  </span>{' '}
                  {IntlUtility.formatPrice({ amount: offer.offer_price })}{' '}
                  <br />
                  <span className={'text-brandRed'}>Expires {daysDiffToString(daysTilOfferExpires)}</span> {' '}
                  <br />
                  <span className='text-black50'>Was{' '}</span>
                      <span className='line-through pl-1 text-black50'>
                       {IntlUtility.formatPrice({ amount: price })}{' '}
                    </span>
                  {strikethrough_price && (
                    <span className='line-through pl-1 text-black50'>
                        {IntlUtility.formatPrice({ amount: strikethrough_price })}
                    </span>
                  )}

              </p>
          </div>
        )
    }
    return (
      <>
      {discount > 50 && strikethrough_price ? (
            <>
                <p className='body-16-bold'>
                    {' '}
                    {IntlUtility.formatPrice({ amount: price })}{' '}
                    {strikethrough_price > 0 && (
                      <span className='line-through pl-1 text-black50'>
                            {IntlUtility.formatPrice({ amount: strikethrough_price })}
                        </span>
                    )}
                </p>
                    <div className="flex items-center justify-between">
                        <div className="body-14-bold text-brandRed font-style: italic">{discount}% off, saving £{amountSavedInPoundsAndPence.toFixed(2)}</div>
                    </div>
                </>
            ) : (
                <div className="flex items-center justify-between">
                    <p className="body-16-bold">
                        {' '}
                        {IntlUtility.formatPrice({amount: price})}{' '}
                        {strikethrough_price && (
                            <span className="line-through pl-1 text-black50">
                                {IntlUtility.formatPrice({amount: strikethrough_price})}
                            </span>
                        )}
                    </p>
                </div>
            )}
        </>
    )
}